<template>
  <div>
    <router-view v-show="$route.matched.length==3"></router-view>
    <PageHeaderLayout v-show="$route.matched.length==2">
      <div class="main-page-content">
        <div class="table-search">
          <el-form :inline="true">
            <el-form-item label="外网IP：">
              <el-input v-model="searchCondition.real_ip" clearable/>
            </el-form-item>
            <el-form-item label="内网IP：">
              <el-input v-model="searchCondition.lan_ip" clearable/>
            </el-form-item>
            <el-form-item label="来源：">
              <el-input v-model="searchCondition.source" clearable/>
            </el-form-item>
            <el-form-item label="提供者：">
              <el-input v-model="searchCondition.provider_name" clearable/>
            </el-form-item>
            <el-form-item label="状态：">
              <el-select v-model="searchCondition.status" style="width: 100px">
                <el-option label="有效" value="Y"></el-option>
                <el-option label="无效" value="N"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="search"><i class="el-icon-search"></i> 搜索</el-button>
              <el-button type="success" @click="addBtn"
                         v-if="userPermissions.indexOf('ip_create') != -1"
              ><i class="el-icon-plus"></i> 新增
              </el-button>
              <el-button type="danger" @click="delRows" :disabled="multipleSelection.length===0"
                         v-if="userPermissions.indexOf('ip_delete') != -1">
                <i class="el-icon-delete"></i> 删除
              </el-button>
            </el-form-item>
          </el-form>
        </div>
        <!--        <el-divider/>-->
        <div class="default-table">
          <ape-table ref="apeTable"
                     :data="tableData"
                     :highlight-current-row="true"
                     :columns="columns"
                     :loading="loadingStatus"
                     :pagingData="pagingData"
                     @selectList="handleSelectionChange"
                     @switchPaging="switchPaging"
                     @changeTableSort="changeTableSort"
                     border>
            <el-table-column
                type="selection"
                width="55" slot="first-column">
            </el-table-column>
            <el-table-column align="center" label="IP来源" width="100">
              <template slot-scope="scope">
                <a :href="`${ip_link}${scope.row.real_ip}`" target="_blank">查看来源</a>
              </template>
            </el-table-column>
            <el-table-column
                label="操作" width="120" align="center">
              <template slot-scope="scope">
                <el-tooltip effect="dark" content="编辑" placement="top-start"
                            v-if="userPermissions.indexOf('ip_create') > -1">
                  <el-button size="mini" icon="el-icon-edit" @click="editButton(scope.row.id)"></el-button>
                </el-tooltip>
                &nbsp;
                <span>
                  <el-popover
                    v-if="userPermissions.indexOf('ip_delete') != -1"
                    placement="top"
                    width="100"
                    v-model="scope.row.visible">
                  <p>确定要删除记录吗？</p>
                  <div style="text-align: right; margin: 0;">
                    <el-button type="text" size="mini" @click="scope.row.visible=false">取消</el-button>
                    <el-button type="danger" size="mini" @click="deleteButton(scope.row.id)">确定</el-button>
                  </div>
                  <el-button slot="reference" type="danger" size="mini"><i class="el-icon-delete"></i></el-button>
                </el-popover>
                </span>
              </template>
            </el-table-column>
          </ape-table>
        </div>

      </div>
      <CreateEdit :visible.sync="dialogVisible" :id="currentId" @refreshData="search"/>
    </PageHeaderLayout>
  </div>
</template>

<script>
import PageHeaderLayout from "@/layouts/PageHeaderLayout";
import { mapGetters } from 'vuex'
import ApeTable from "@/components/ApeTable";
import CreateEdit from "./CreateEdit"

export default {
  name: "NetworkIpList",
  components: {
    ApeTable,
    PageHeaderLayout, CreateEdit
  },
  data() {
    return {
      tableData: [],
      loadingStatus: false,
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        page_size: 20,
        offset: 0,
      },
      // 分页的offset,序号列使用
      // offset: 0,
      columns: [
        {
          title: '外网IP',
          value: 'real_ip',
          width: 120,
          sortable: 'custom'
        },
        {
          title: '内网IP',
          value: 'lan_ip',
          width: 120,
          sortable: 'custom'
        },
        {
          title: '来源',
          value: 'source',
          width: 160,
          sortable: 'custom'
        },
        {
          title: '提供者',
          value: 'provider_name',
          width: 120,
          sortable: 'custom'
        }, {
          title: '状态',
          value: 'status',
          width: 80,
          sortable: 'custom'
        },
        {
          title: '备注',
          value: 'remark',
          width: 280
        },
        {
          title: '创建时间',
          value: 'created_at',
          width: 120,
          sortable: 'custom'
        }, {
          title: '修改时间',
          value: 'updated_at',
          width: 120,
          sortable: 'custom'
        },
      ],
      searchCondition: { lan_ip: null, real_ip: null, source: null, provider_name: null },
      orderSort: { id: 'desc' },
      multipleSelection: [],//多选选中的数据
      dialogVisible: false,
      currentId: null,
      ip_link: '',//IP的地址查询
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType'])
  },
  watch: {},
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 切换页码操作
    async switchPaging() {
      await this.initList()
    },
    changeTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.initList()
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}

      if (this.searchCondition.real_ip) {
        condition.real_ip = this.searchCondition.real_ip
      }
      if (this.searchCondition.lan_ip) {
        condition.lan_ip = this.searchCondition.lan_ip
      }
      if (this.searchCondition.source) {
        condition.source = this.searchCondition.source
      }
      if (this.searchCondition.provider_name) {
        condition.provider_name = this.searchCondition.provider_name
      }
      if (this.searchCondition.status) {
        condition.status = this.searchCondition.status
      }
      return condition
    },
    async initList(type) {
      this.loadingStaus = true
      this.tableData = []
      let pagingInfo = this.$refs['apeTable'].getPagingInfo(type)
      let searchCondition = this.handleSearchCondition()
      // 合并
      // Object.assign(searchCondition, pagingInfo)
      Object.assign(searchCondition, pagingInfo, { order: this.orderSort })
      let { list, pages, ip_link } = await this.$api.getNetworkIpList(searchCondition)
      this.ip_link = ip_link
      this.$nextTick(() => {
        this.tableData = list
      })
      this.pagingData.total = pages.total
      // this.offset = pages.offset
      this.pagingData.offset = pages.offset
      this.loadingStaus = false
    },
    search() {
      this.$refs['apeTable'].handleCurrentChange(1)
    },
    addBtn() {
      this.currentId = null
      this.dialogVisible = true
    },
    editButton(id) {
      this.currentId = id
      this.dialogVisible = true
    },
    async deleteButton(id) {
      let info = await this.$api.delNetworkIp(id);
      if (info == 'ok') {
        this.$nextTick(async () => {
          await this.initList('del')
        })
      } else {
        this.$message.error(info)
      }
    },

    async delRows() {
      let ids = this.multipleSelection.map((item) => {
        return item.id
      })
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await this.$api.delMultipleNetworkIp(ids);
        await this.initList()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });


    }
  },
  mounted() {
    this.search()
  }
}
</script>

<style scoped>

</style>
