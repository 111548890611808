<template>
  <div>
    <el-dialog v-bind="$attrs" v-on="$listeners" @open="onOpen" @close="onClose" :title="dialogTitle">
      <el-form ref="elForm" :model="formData" label-width="100px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="外网IP：">
              <el-input v-model="formData.real_ip" placeholder="外网IP" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="内网IP：">
              <el-input v-model="formData.lan_ip" placeholder="内网IP" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="访问来源：">
              <el-input v-model="formData.source" placeholder="访问来源，例如：东谷三楼" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="提供者：">
              <el-input v-model="formData.provider_name" placeholder="提供者，例如：王飞" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="状态：" prop="status">
              <el-select v-model="formData.status" placeholder="状态" clearable>
                <el-option :label="item.label" :value="item.value" v-for="(item,key) in statusOption"
                           :key="key">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item>
              <el-button type="primary" @click="saveBtn" :disabled="loadingCommit" :loading="loadingCommit"
                         v-if="userPermissions.indexOf('ip_create')>-1">保存
              </el-button>
              <el-button type="default" @click="close">关闭</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "CreateEdit",
  computed: {
    ...mapGetters(['userPermissions', 'userInfo']),
    dialogTitle() {
      return `${ this.id ? '编辑（ID：' + this.id + '）' : '新增' }`;
    }
  },
  props: {
    id: {
      type: [String, Number],
      default() {
        return null
      }
    }
  },
  data() {
    return {
      loadingCommit: false,
      formData: { lan_ip: null, real_ip: null, source: null, status: 'Y', provider_name: '' },
      statusOption: [
        { label: '有效', value: 'Y' },
        { label: '无效', value: 'N' },
      ]
    }
  },
  methods: {
    onOpen() {
      this.loadingCommit = false
      this.formData = { lan_ip: null, real_ip: null, source: null, status: 'Y', provider_name: this.userInfo.nickname }

      this.getAInfo()
    },
    onClose() {
      this.$refs['elForm'].resetFields()
      this.$emit('refreshData')
    },
    close() {
      this.$emit('update:visible', false)
    },
    async saveBtn() {
      this.loadingCommit = true
      let postData = { ...this.formData }
      if (this.id) {
        postData['id'] = this.id
      }
      let info = await this.$api.saveNetworkIp(postData)
      if (info) {
        this.$message({ showClose: true, message: "保存成功", type: "success" })
        this.loadingCommit = false
        this.close()
      }
    },
    async getAInfo() {
      if (this.id) {
        let { info } = await this.$api.getNetworkIp(this.id)
        if (info) {
          this.formData = {
            lan_ip: info.lan_ip,
            real_ip: info.real_ip,
            source: info.source,
            status: info.status,
            provider_name: info.provider_name

          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
